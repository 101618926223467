.create-signature-container {
    height: 65px;
    width: 100px;
    border: 1px solid black;
    text-align: center;
    box-sizing: border-box;
    border-style: dashed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-container-signature {
    position: relative;

    .cross-btn-signature {
        position: absolute;
        right: -21px;
        top: -12px;
        background: #d9d9d9;
        border-radius: 50%;
        padding: 7px 7px;
        z-index: 9999;

        &:hover {
            cursor: pointer;
        }
    }
}
.input-disabled {
    input,
    textarea {
        pointer-events: none !important;
    }
}

.print-button {
    display: block;
}

@media print {
    button.print-button {
        display: none !important;
    }
}

.landscape-mode {
    @media screen {
        .bg_body {
            width: 1500px !important;
            min-width: 1500px !important;
            margin: 20px auto !important;
            transform-origin: top center !important;
        }
    }

    @media print {
        .bg_body {
            width: 100% !important;
            transform: rotate(0deg) !important;
        }
    }
}


@primary-color: #1DA57A;